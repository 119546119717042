<template>
  <a v-if="owner">
    <div @click="gotoPerson(owner)" class="cont-avatar">
      <img v-if="owner.photo" :src="owner.photo">
      <div v-else class="img-fake">{{ firstLettersName(owner.name) }}</div>
    </div>
    <div class="show-user">
      <strong @click="gotoPerson(owner)">{{ owner.name }}</strong>
      <span @click="gotoPerson(owner)">Proprietário</span>
      <!--              <i class="fas fa-sort-down"></i>-->
      <u-btn @click="$refs.popover.toggle()" title="Transferir propriedade" icon="sort-down" icon-type="fa" size="xs" class="simple-btn no-border" flat
             outline dense color="white">
        <popover-owner ref="popover" :pessoa="pessoa" />
      </u-btn>
    </div>
  </a>
  <a @click="$refs.popover.toggle()" v-else>
    <div class="show-user">
      <strong>Nenhum Responsável</strong>
      <span>Clique para adicionar</span>
    </div>
    <popover-owner ref="popover" :pessoa="pessoa" />
  </a>
</template>

<script>
import PessoaMixin from './mixin'
import PopoverOwner from "@/components/pessoa/components/include/pessoa/PopoverOwner"

export default {
  components: {PopoverOwner},
  mixins: [PessoaMixin],
  name: "PessoaOwner",
  props: ['owner'],
  methods: {
    desvincular () {
      this.$refs.popover.desvincular()
    }
  }
}
</script>
